import { MenuEntry } from 'corx-ui'

const config: MenuEntry[] = [
  {
    label: ' Home',
    icon: 'HomeIcon',
    href: 'https://hpbswap.42web.io/',
  },
  {
    label: ' Swap',
    icon: 'TradeIcon',
    href: 'https://swap.hpbswap.42web.io/',
  },
  {
    label: ' Earn',
    icon: 'TicketIcon',
    href: 'https://earn.hpbswap.42web.io/',
  },
  
  
  
  
]

export default config
